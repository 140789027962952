/*
 * Helpers and constants used globally
 */

import { useRef, useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import toast from 'react-hot-toast';
import crypto from "crypto";

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return (() => {
      isMounted.current = false;
    });
  }, []);

  return isMounted;
}

export let mobileForTest = false;

export const isAndroid = () => navigator.userAgent.indexOf("android") > -1;
export const isSamsung = () => navigator.userAgent.indexOf("SamsungBrowser") > -1;
export const isAppleiOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
};
export const hasTinyScreen = () => window.matchMedia('(max-width: 600px)').matches;
export const isMobile = () => isAndroid() || isSamsung() || isAppleiOS() || hasTinyScreen() || mobileForTest;

export function getRandomValue(): number{
  return crypto.randomBytes(4).readUInt32LE(0) / 0x100000000;
}

export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(getRandomValue() * (max - min) + min);
}

export function updatedAt(now = new Date()): string {
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const oMinutes = minutes < 10 ? `0${minutes}` : minutes;

  let time;
  if (hours > 12) time = `${hours - 12}:${oMinutes} PM`;
  else if (hours === 12) time = `12:${oMinutes} PM`;
  else if (hours === 0) time = `12:${oMinutes} AM`;
  else time = `${hours}:${oMinutes} AM`;

  return time.toString();
}

export function toHash(arr: any[], key: string, val: string) {
  return arr.length > 0 ? arr.reduce(function (map: {}, obj: { key: any, val: any }): any {
    map[obj.key] = obj.val;
    return map;
  }) : {};
}

//takes a hash, and converts its key's to a string array
export function toArray(hash:{}) {
  let arr = [];
  for (const key in hash) {
    arr.push(key);
  }
  return arr;
}

export function usCurrencyFormat(num) {
  if (typeof (num) === 'number') {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  } else {
    return '';
  }
}

export function shortenedCurrencyFormatMillions(num) {
  let displayValue = Math.round((num / 10000)) / 100;
  return `${usCurrencyFormat(displayValue)}M`;
}

export function shortenedCurrencyFormat(num) {
  let displayValue = Math.round((num / 10)) / 100;
  return `${usCurrencyFormat(displayValue)}K`;
}

export function usCurrencyFormatShortenedToMillions(num:number, type) {
  if (type) {
    switch (type) {
      case 'millions':
        return shortenedCurrencyFormatMillions(num);

      case 'thousands':
        return shortenedCurrencyFormat(num);

      case 'full':
        return usCurrencyFormat(num)
    }

  } else {
    if (num >= 1000000) return shortenedCurrencyFormatMillions(num);
    if (num >= 1000) return shortenedCurrencyFormat(num);
    return usCurrencyFormat(num);
  }
}

export function percentageSuffix(num) {
  if (typeof (num) === 'number') {
    return `${num}%`;
  } else {
    return '';
  }
}

export function takeFirstIfExistWithZeroAccepted(first, second) {
  return first || first === 0 ? first : second;
}

export function saveUserDataToSessionStorage(userData){
  try{
    sessionStorage.setItem("userData", JSON.stringify(userData.attributes));
    sessionStorage.setItem("accessToken", userData.signInUserSession.accessToken.jwtToken);
    sessionStorage.setItem("expValue", userData.signInUserSession.accessToken.payload.exp);
    sessionStorage.setItem("userId", userData.attributes.sub);
    const userRoles = (userData.signInUserSession.accessToken.payload) ? userData.signInUserSession.accessToken.payload["cognito:groups"] : [];
    sessionStorage.setItem("userRoles", JSON.stringify(userRoles));
    return true
  } catch(e){
    return false
  }
}

interface UserDataInterface {
  name: string;
  lastName: string;
  email: string;
  roles: string;
}

export function getUserDataFromSessionStorage():UserDataInterface{
  let userData = sessionStorage.getItem("userData");
  try{
    userData = JSON.parse(userData);
  }catch(e){
    console.error("User data is not valid!");
  }

  return {
    name: (userData && userData['given_name']) || '',
    lastName: (userData && userData['family_name']) || '',
    email: (userData && userData['email']) || '',
    roles: getUserRolesFromSessionStorage()
  };
}

export function getUserIdFromSessionStorage(){
  return sessionStorage.getItem("userId");
}

export function getUserRolesFromSessionStorage(): string{
  const userRolesData = sessionStorage.getItem("userRoles");
  if (!userRolesData) return '';
  let userRoles;

  try{
    userRoles = JSON.parse(userRolesData);
  }catch(e){
    console.error("User roles are not valid!");
  }

  if (!userRoles || !userRoles.length) return '';

  // Check admin Role
  let allRoles = '';
  userRoles.forEach((currentRole: string) => {
    if (currentRole.toLowerCase().indexOf('role:') >= 0){
      allRoles += (allRoles) ? ', ' : '';
      allRoles += currentRole.toLowerCase().substr(5);
    }
  });

  return allRoles;
}


export function setAppTour(appTour){
  localStorage.setItem("alchemaiAppTour", appTour);
}

export function setAppTourStepNumber(appTourStep){
  localStorage.setItem("alchemaiAppTourStepNumber", JSON.stringify(appTourStep));
}

export function clearAppTour(){
  localStorage.removeItem("alchemaiAppTour");
}

export function clearAppTourStepNumber(){
  localStorage.removeItem("alchemaiAppTourStepNumber");
}

export function clearUserDataToSessionStorage(){
  sessionStorage.removeItem('accessToken');
  sessionStorage.removeItem('expValue');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('userRoles');
  sessionStorage.removeItem('userIsAdmin');
}

export function getAppTour(){
  return localStorage.getItem("alchemaiAppTour");
}

export function getAppTourStepNumber(){
  return JSON.parse(localStorage.getItem("alchemaiAppTourStepNumber"));
}

export function validateUserRole(role:string): boolean{
  const userRolesData = sessionStorage.getItem("userRoles");
  if (!userRolesData) return false;
  let userRoles;

  try{
    userRoles = JSON.parse(userRolesData);
  }catch(e){
    console.error("User roles saved are not valid!");
  }

  if (!userRoles || !userRoles.length) return false;

  // Check admin Role
  let userIsRole = false;
  userRoles.forEach((currentRole: string) => {
    if (currentRole.toLowerCase() === `role:${role.toLowerCase()}`){
      userIsRole = true;
    }
  });

  return userIsRole;
}

export function validateUserRoles(roles: string[]){
  let validRoles = true;
  roles.forEach((role) => {
    if (!validateUserRole(role)){
      validRoles = false;
    };
  });

  return validRoles;
}

export function validateAnyUserRoles(roles: string[]){
  let validRoles = false;
  roles.forEach((role) => {
    if (validateUserRole(role)){
      validRoles = true;
    };
  });

  return validRoles;
}

export function refreshToken(callBack?, args?):Boolean {
  const expValue = sessionStorage.getItem("expValue");
  if ((Date.now()/1000 > parseInt(expValue))) {
    Auth.currentSession()
    .then(data => {
      // @ts-ignore
      sessionStorage.setItem("accessToken", data.accessToken.jwtToken);
      // @ts-ignore
      sessionStorage.setItem("expValue", data.accessToken.payload.exp);
      callBack&&callBack(...args);

    })
    .catch(err => console.log(err));
    return true;
  }
  else {
    return false;
  }
}

export function logScale(int, logScaleVal):number {
    return Math.log(int) / Math.log(logScaleVal);
}

export const companyIdRegExp = /^[a-zA-Z0-9+._-\s]{3,}$/;
export const mentionsRegExp = /[\{\[]+['"]*id['"]*:[ '"]*[0-9-a-z]+['"]*,[ "]*name["]*:[ '"]*[@#$][a-zA-Z0-9-.]+[ '"]*[\}\]]+/g;
export const emailRegExp = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
export const nameRegExp = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/;
export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()[\]{}\-_=+|\\//?.,<>:;])[a-zA-Z0-9!@#$%^&*()[\]{}\-_=+|\\//?.,<>:;]{8,}/;

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const showToastMessage = (message: string, type?: string, options?) => {
  if (!message) return;

  let toastId: string;
  const toastOptions = {
    position: options?.position || 'bottom-center',
    style: options?.position ||  {
      background: '#363636',
      color: '#fff',
      minWidth: '30%',
    },
    id: options?.id || '',
  };

  if (options?.duration){
    toastOptions['duration'] = options?.duration;
  }

  switch(type){
    case 'success':
      toastId = toast.success(message, toastOptions);
      break;

    case 'error':
      toastId = toast.error(message, toastOptions);
      break;

    case 'loading':
      toastId = toast.loading(message, toastOptions);
      break;

    case 'alert':
      const customOptions = {
        duration: 5000,
        position: toastOptions.position,
        style: toastOptions.style,
        icon: '⚠',
        id: options?.id || null,
      };
      toast(message, customOptions);
      break;

    default:
      toastId = toast(message, toastOptions);
      break;
  }

  return toastId;
}

export const hideToastMessage = (toastId?: string) => {
  toastId ? toast.dismiss(toastId) : toast.dismiss();
}

export const sendPageViewToGA = (page: string, title: string) => {
  (window as any).gtag("config", process.env.NX_GOOGLE_ANALYTICS_CODE, {
      page_title: title,
      page_path: page,
  })
}

export const sendEventToGA = (action: string, category: string, label: string, value: string) => {
  (window as any).gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value
  });
}
