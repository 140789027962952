import { AxiosResponse } from "axios";

export interface SimCategories {
  valueProp: string;
  value: string;
}

export interface NetworkCompanyInfoQueryResults {
  companyId: string;
  companyName: string;
  companyFunction: string;
  parentName: string;
  companyRiskScore: number;
  companyImpactScore: number;
  companySimRiskScore: number;
  companySimImpactScore: number;
  companyCity: string;
  companyCountry: string;
}

export interface AggregatedByTierProps {
  companyTier: number;
  networkCompanyInfoQueryResults: NetworkCompanyInfoQueryResults[];
}

export interface CreateNewSimProps {
  scName?: string;
  scRiskScore?: number;
  rfLabel?: string;
  rfKey?: string;
  afNodesArr?: {}[];
  id?: string;
  adjAllScores?: number;
  setSelectedNodeIsChecked?: (
    name: string,
    isChecked: boolean,
    isAll?: boolean) => void;
  selectedNodes?: {}[];
}


export interface SingleSimulatorProps {
  creationDate: string;
  category: string;
  name: string;
  isPrivate: boolean;
  id: string;
  comments: {
    creatorName: string;
    content: string;
    id: string;
  }[];
  creator: {
    email: string;
    id: string;
    name: string;
  };
  inputs: [];
  scenario: string;
  status: string;
}

export const categories: SimCategories[] = [
  { valueProp: "CYBER", value: 'Cyber' },
  { valueProp: 'FINANCIAL', value: 'Financial' },
  { valueProp: 'GEOPOLITICAL', value: 'Geopolitical' },
  { valueProp: 'GEOTECHNICAL', value: 'Geotechnical' },
  { valueProp: 'NATURAL_DISASTER', value: 'Natural disaster' },
  { valueProp: 'PERFORMANCE', value: 'Performance' },
  { valueProp: 'TARIFFS', value: 'Tariffs' },
  { valueProp: 'WORK_DISRUPTIONS', value: 'Work disruptions' }
]

export const riskFactors: CreateNewSimProps[] = [
  { rfLabel: "Risk (Timing)", rfKey: "overall" },
  { rfLabel: "Geopolitical Risk", rfKey: "geopolitical" },
  { rfLabel: "Financial Risk", rfKey: "financial" },
  { rfLabel: "Counterfeit Risk", rfKey: "counterfeit" }
]

export function mapToAggregatedByTierProps(response: AxiosResponse<any>) {
  if (response) {
    const responseData = response.data;
    const grouped = groupBy(responseData, toGroup => toGroup.tier);
    const resultList: AggregatedByTierProps[] = [];
    for (const entry of grouped.entries()) {
      resultList.push({
        companyTier: entry[0],
        networkCompanyInfoQueryResults: entry[1].map(entry => {
          return {
            companyId: entry.companyId || '',
            companyName: entry.companyName,
            companyFunction: entry.companyFunction,
            parentName: entry.parentName,
            companyRiskScore: entry.riskScore,
            companyImpactScore: entry.impactScore,
            companySimRiskScore: entry.simulatedRiskScore,
            companySimImpactScore: entry.simulatedImpactScore,
            companyCity: entry.companyCity,
            companyCountry: entry.companyCountry
          }
        })
      });
    }
    return resultList;
  }
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function getPercentageChange(score1: number, score2: number) {
  return ((score1 - score2) / score1) * 100;
}

export function getTextColor(score: number, indicator: string) {
  switch (indicator) {
    case 'up':
      return score > 0 && score <= 10 ? '#1E90FF' : '#106DC7';
    case 'down':
      return score > 0 && score <= 10 ? '#F19CBB' : '#FF0000';
  }
}

export function getPercentageChangeColor(score, simScore) {
  return score > simScore ? {
    textColor: getTextColor(getPercentageChange(score, simScore), "down"),
    percentageChange: "Down by " + getPercentageChange(score, simScore).toFixed(1) + ' %'
  }
    : score < simScore ? {
      textColor: getTextColor(getPercentageChange(score, simScore), "up"),
      percentageChange: "Up by " + (-1 * getPercentageChange(score, simScore)).toFixed(1) + ' %'
    }
      : {
        textColor: '#6f6f70',
        percentageChange: '0 %'
      }
}