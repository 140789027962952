import { createStore, compose } from 'redux';
import { DispatchAction, InitialState, rootReducer } from './root-reducer';
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Nothing to do here
});

const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  || compose;

export const _store = createStore<InitialState, DispatchAction, null, null>(
  rootReducer, composeEnhancer(sentryReduxEnhancer)
  // (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
  // sentryReduxEnhancer
);
