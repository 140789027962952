import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { LicenseManager } from '@ag-grid-enterprise/core';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// LOCAL IMPORTS
import App from './app/app';
import { _store } from './app/redux/store/index';
import 'leaflet/dist/leaflet.css';

// AG GRID ENTERPRISE LICENSE KEY //
LicenseManager.setLicenseKey(
  'CompanyName=Alchemai,LicensedApplication=Alchemai EDGE,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-017791,ExpiryDate=10_September_2022_[v2]_MTY2Mjc2NDQwMDAwMA==c713c68f03f6b5907c0c48f971eeaeb9'
);

// SENTRY Integration
Sentry.init({
  dsn: process.env.NX_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: parseFloat(process.env.NX_SENTRY_TRACE_LEVEL),
  maxBreadcrumbs: 50,
  environment: process.env.NX_SENTRY_ENVIRONMENT,
});

ReactDOM.render(
  <Provider store={_store}>
    <App />  
  </Provider>,
  document.getElementById('root')
);
