/*
 * Helpers and constants used on the network pages
 */

import { useRef, useEffect } from 'react';

export function orderNetworkData(data){
  // Order by Tier
  data.sort((a, b) => (a.companyTier < b.companyTier) ? -1 : 1);

  // Reorder Tiers (ensuring companies are available on tier 1 and 2)
  if (data[1] && data[1].networkCompanyInfoQueryResults.length > 0 && 
    data[2] && data[2].networkCompanyInfoQueryResults.length > 0){
      const tier1Companies = data[1].networkCompanyInfoQueryResults;
      let orderedData = [];

      // Go thru every company in tier 2 to know children of companies in tier 1
      data[2].networkCompanyInfoQueryResults.forEach((item) => {
        // Search parent company by name (in tier 1)
        let found = false;
        for (let i = 0; i < tier1Companies.length && !found; i++){
          if (tier1Companies[i].companyName === item.parentName){
            found = true;
            // Add company index to
            (tier1Companies[i].childCompanies) ? tier1Companies[i].childCompanies.push(item) : (tier1Companies[i].childCompanies = [item]);
          }
        }
      });

      // Reorder Tier 1 by children count
      tier1Companies.sort((a, b) => {
        const cmpAChildren = (!a.childCompanies) ? 0 : a.childCompanies.length;
        const cmpBChildren = (!b.childCompanies) ? 0 : b.childCompanies.length;
        return (cmpAChildren < cmpBChildren) ? -1 : 1;
      });

      // Reorder companies to get the most children to the center
      let orderLowFirst = true;
      let orderLow = 0;
      let orderHigh = tier1Companies.length - 1;
      tier1Companies.forEach((item) => {
        (orderLowFirst) ? orderedData[orderLow] = item : orderedData[orderHigh] = item;
        (orderLowFirst) ? orderLow++ : orderHigh--;
        orderLowFirst = !orderLowFirst;
      });

      // Get Tier 2 companies ordered from tier 1 companies order
      let tier2Data = [];
      for (let i = tier1Companies.length - 1; i >= 0; i--){
        tier1Companies[i].childCompanies && tier1Companies[i].childCompanies.forEach((item) => {
          // If company is not already added, add it to the array
          if (!tier2Data.find((element) => element.companyName === item.companyName)){
            tier2Data.push(item);
          }
        });
      }

      // Re-order Tier 2 companies from center to the sides
      let orderedTier2Data = [];
      let orderTier2LowFirst = true;
      let orderTier2Low = 0;
      let orderTier2High = tier2Data.length - 1;
      for (let i = tier2Data.length - 1; i >= 0; i--){
        const item = tier2Data[i];
        (orderTier2LowFirst) ? orderedTier2Data[orderTier2Low] = item : orderedTier2Data[orderTier2High] = item;
        (orderTier2LowFirst) ? orderTier2Low++ : orderTier2High--;
        orderTier2LowFirst = !orderTier2LowFirst;
      }
      
      // Save Data to response
      data[1].networkCompanyInfoQueryResults = orderedData;
      data[2].networkCompanyInfoQueryResults = orderedTier2Data;
  }

  return data;
}