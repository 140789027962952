import {RootDispatcher} from '../src/app/redux/store/root-reducer';
import axios from 'axios';
import {mapToAggregatedByTierProps} from "../src/app/helpers/simulator-helpers";
import { refreshToken, showToastMessage } from './../src/app/helpers/global-helpers';
import { orderNetworkData } from 'app/helpers/network-helpers';

import { format as formatDate } from 'date-fns';

const api = process.env.NX_REACT_APP_API_URL;

export function getCompaniesList(dispatch): void {
  if (refreshToken(getCompaniesList, arguments)) return;

  const rootDispatcher = new RootDispatcher(dispatch);
  axios.get(`${api}/companies/mine`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
  })
    .then(function (response) {
      if (response.data.length > 0) {
        rootDispatcher.updateCompanyList(response.data);
      } else {
        console.error('Companies list is unavailable!');
      }
    })
    .catch(function (error) {
      console.error('Error occured while loading companies list!');
    });
}

export async function getInitialTreeData(dispatch): Promise<any> {
  let returnData = null;

  if (refreshToken(getInitialTreeData, arguments)) return returnData;

  const rootDispatcher = new RootDispatcher(dispatch);
  await axios
    .get(`${api}/companies/tree-views`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
      if (response) {
        rootDispatcher.updateTempNodeTree({});

        // Order data
        const orderedData = orderNetworkData(response.data);

        // Save data
        rootDispatcher.updateTreeTiersList(orderedData);
        returnData = orderedData;
      } else {
        console.error('Response not found.');
        returnData = false;
      }
    })
    .catch(function (error) {
      console.error('Error occurred while loading initial tree data.');
      console.error(error);
      returnData = false;
    });

    return returnData;
}

export function getSimulatedInitialTreeData(dispatch, simulationId) {
  if (refreshToken(getSimulatedInitialTreeData, arguments)) return;

  const rootDispatcher = new RootDispatcher(dispatch);
  axios
    .get(`${api}/simulations/${simulationId}/tree-views`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
        const result = mapToAggregatedByTierProps(response);
        rootDispatcher.updateTreeTiersList(result);
        rootDispatcher.updateTempNodeTree({});
      }
    )
    .catch(function (error) {
      console.error('Error occurred while loading initial tree data for simulation.');
      console.error(error);
    });
}

export function getZoomedTreeData(dispatch, companyName, type, level) {
  if (refreshToken(getZoomedTreeData, arguments)) return;

  const rootDispatcher = new RootDispatcher(dispatch);
  axios
    .get(`${api}/companies/tree-views/${companyName}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
      if (response) {

        // Order data
        const orderedData = orderNetworkData(response.data);

        // Remove companies from tier 0 except the one selected
        let newTier1Data = orderedData[0] ? orderedData[0].networkCompanyInfoQueryResults : [];
        for (let i = newTier1Data.length -1 ; i >= 0; i--){
          if (newTier1Data[i].companyName !== companyName) newTier1Data.splice(i, 1);
        }

        if (type === "network") {
          rootDispatcher.updateCompanyTreeList({
            parentCompanyName: companyName,
            companyTreeList: orderedData
          })
          if (level === "level1"){
              rootDispatcher.updateTempNodeTree({
              parentCompanyName: companyName,
              companyTreeList: orderedData
            })
          }
        } else {
          rootDispatcher.updateNodeTree({
            parentCompanyName: companyName,
            companyTreeList: orderedData
          });
        }

        return orderedData;
      }

      console.error("Response not found.");
      return false;

    })
    .catch(function (error) {
      console.error(`Error occurred while loading zoomed tree data [company: ${companyName}].`);
      console.error(error);
    });
}

export function getSimulatedZoomedTreeData(dispatch, simulationId, companyName, companyTierLevel) {
  if (refreshToken(getSimulatedZoomedTreeData, arguments)) return;

  const rootDispatcher = new RootDispatcher(dispatch);
  axios
    .get(`${api}/simulations/${simulationId}/tree-views/${companyName}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {

      if (response) {
        const result = mapToAggregatedByTierProps(response);
        rootDispatcher.updateCompanyTreeList({
          parentCompanyName: companyName,
          companyTreeList: result
        })
        if(companyTierLevel === "level1"){
          rootDispatcher.updateTempNodeTree({
          parentCompanyName: companyName,
          companyTreeList: result
        })
      }
      } else {
        showToastMessage(companyName + ' Data is unavailable!', 'error');
      }
      return response.data;
    })
    .catch(function (error) {
      console.error(`Error occurred while loading zoomed tree data [company: ${companyName}] for simulation.`);
      console.error(error);
    });
}

export function getInitialClusterData(dispatch) {
  if (refreshToken(getInitialClusterData, arguments)) return;

  const rootDispatcher = new RootDispatcher(dispatch);
  axios
    .get(`${api}/companies/cluster-views`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
      if (response) {
        rootDispatcher.updateClusterTiersList(response.data);
      } else {
        console.error("Response not found.");
      }
      return response.data;
    })
    .catch(function (error) {
      console.error('Error occurred while loading initial cluster data.');
      console.error(error);
    });
}

export function getSimulatedInitialClusterData(dispatch, simulationId) {
  if (refreshToken(getSimulatedInitialClusterData, arguments)) return;

  const rootDispatcher = new RootDispatcher(dispatch);
  axios
    .get(`${api}/simulations/${simulationId}/cluster-views`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
      const result = mapToAggregatedByTierProps(response);
      if (response) {
        rootDispatcher.updateClusterTiersList(result);
      } else {
        console.error("Response not found.");
      }
      return result;
    })
    .catch(function (error) {
      console.error('Error occurred while loading initial cluster data for simulation.');
    });
}

export const getNodeDetails = (dispatch, companyName, startDate, endDate, riskScore, callback?) => {
  if (refreshToken(getNodeDetails, [dispatch, companyName, startDate, endDate, riskScore, callback])) return;

  const rootDispatcher = new RootDispatcher(dispatch);
  const query =
    `${api}/companies/` +
    companyName +
    '?startDate=' +
    startDate +
    '&endDate=' +
    endDate;

  axios
    .get(query, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      }
    })
    .then(function (response) {
      if (!response.status || !response.data){
        !callback && showToastMessage(companyName + ' information is unavailable', 'error');
        callback && callback(false);
        return;
      }

      if (riskScore === null) {
        riskScore = response.data.originalRiskScore;
      }

      const riskArray = [];
      const dtArray = [];
      response.data.calculations.map(row => {
        riskArray.push(row.riskScore)
        dtArray.push(formatDate(row.timestamp, "MM-DD"));
      });

      const latestNodeData = (response.data.calculations.length > 0) ?
        response.data.calculations[response.data.calculations.length - 1]
      :
        { impactScore: 0, revenueAtRiskScore: 0, riskScore: 0 };

      rootDispatcher.updateNodeDetailData({
        impactScore: latestNodeData.impactScore,
        revenue: latestNodeData.revenueAtRiskScore,
        riskScore: latestNodeData.riskScore,
        riskTrend: latestNodeData.riskTrend,
      });

      // Update Node RiSK Trends
      rootDispatcher.updateNodeDetailRiskTrends({
        date: dtArray,
        riskScore: riskArray
      })
      // Update
      if (riskScore !== -1) {
        rootDispatcher.updateNodeDetailInfo({
          cmpId: response.data.id,
          cmpName: response.data.name,
          cmpTier: 'Tier',
          cmpFunction: response.data.companyFunction,
          cmpCountry: response.data.country,
          cmpCity: response.data.city,
          cmpImpactFactors: response.data.impactFactors,
          cmpCompetitors: response.data.competitors,
        });
        rootDispatcher.updateNodeRiskOverviewObj({
          overallTimingRisk: latestNodeData.riskScore
        });
        response.data.children.length > 0 ?
          getZoomedTreeData(dispatch, response.data.name, "node", "level0")
        :
          getZoomedTreeData(dispatch, response.data.parent?.name, "node", "level0")

        const orderArray = [];
        response.data.orders.map((order,idx)=>{
          orderArray.push({
            dueDate: order.dueDate.split("T")[0],
            receiptDate: order.receiptDate.split("T")[0],
            orderID: order.partNumber+""+idx
          })
        })
        // Update Timing of deliveries list
        // rootDispatcher.updateNodeTimingDeliveriesList({
        //   startDate : startDate,
        //   endDate : endDate,
        //   nodeTimingDeliveriesList: orderArray
        // });
      }

      callback && callback(true);
    })
    .catch(function (error) {
      !callback && showToastMessage(companyName + ' information is unavailable', 'error');
      callback && callback(null);
    });
};

// SIMULATOR
// TODO move to new file SimulatorApi.tsx
export function getSimulatorsList(dispatch): void {
  if (refreshToken(getSimulatorsList, arguments)) return;

  const rootDispatcher = new RootDispatcher(dispatch);
  axios
    .get(`${api}/simulations?filterBy=MINE`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
      if (response.data) {
        rootDispatcher.updateSimulationList(response.data);
      } else {
        console.error('Simulations list not returned!');
      }
    })
    .catch(function (error) {
      console.error('Error occured while loading simulations list!');
    });
}

// Get only Shared simulations
export function getSimulatorsSharedList(dispatch): void {
  if (refreshToken(getSimulatorsSharedList, arguments)) return;

  const rootDispatcher = new RootDispatcher(dispatch);
  axios
    .get(`${api}/simulations?filterBy=SHARED`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
      if (response.data) {
        rootDispatcher.updateSimulationSharedList(response.data);
      } else {
        console.error('Simulations shared list not returned!');
      }
    })
    .catch(function (error) {
      console.error('Error occured while loading simulations shared list!');
    });
}

export function deleteSimulator(simID, dispatch): void {
  if (refreshToken(deleteSimulator, arguments)) return;

  axios
    .delete(`${api}/simulations/${simID}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
      getSimulatorsList(dispatch);
      return true;
    })
    .catch(function (error) {
      showToastMessage('Error deleting simulation!', 'error');
    });
}

interface ValidateSimNameArgs {
  name: string;
  success?: any;
  failure?: any;
}

interface SimNameAxiosReturn {
  status: number;
  data: any;
  message?: any;
}

export function validateNewSimName(args: ValidateSimNameArgs): void {
  if (refreshToken(validateNewSimName, arguments)) return;

  axios
    .get(`${api}/simulations/check-name?name=${encodeURIComponent(args.name)}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then((response: SimNameAxiosReturn) => {
      if (!response.data){
        if (typeof args.failure === 'function') {
          args.failure('Name not valid or already used!');
        }
        return;
      }

      if (typeof args.success === 'function') {
        args.success();
      }
    })
    .catch((error) => {
      console.log('Error validating SIM name!');
      const errorMessage = (error.response && error.response.data && error.response.data.message) ?
        error.response.data.message 
      : 
        'Name is not valid!';

      if (typeof args.failure === 'function') {
        args.failure(errorMessage);
      }
    });
}

export interface SubmitSimulationResult {
  status: boolean;
  error?: string;
  data?: any;
}

export async function submitSimulation(
  {
    name,
    isPrivate,
    sendToMyEmail,
    scenario,
    category,
    inputs,
    comments
  }): Promise<SubmitSimulationResult> {

  let result: SubmitSimulationResult = { status: false };

  if (refreshToken(submitSimulation, arguments)) return;

  await axios.post(`${api}/simulations`, {
    name: name,
    isPrivate: isPrivate,
    sendToMyEmail: sendToMyEmail,
    scenario: scenario,
    category: category,
    inputs: inputs,
    comments: comments,
  },{
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
  })
  .then((response) => {
    if (response.status) {
      result = { status: true, data: { id: response.data.id, name: response.data.name } };
      return;
    }

    const message = (response.data && response.data.message) ?
      response.data.message
    : 'Simulation couldn\'t be created!';

    result = { status: false, error: message};
  }, (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ?
      error.response.data.message
    : 'Error creating simulation!';

    result = { status: false, error: message};
  });

  return result;
}

export interface CompanyData {
  name?: string;
  impactFactors?: string;
  competitors?: string;
  previousImpactFactors?: string;
  previousCompetitors?: string;
}

export interface CompanyDetailsResult {
  status: boolean;
  error?: string;
}

export async function editCompanyDetails(companyData: CompanyData): Promise<CompanyDetailsResult> {
  let data: CompanyData = {};
  let result: CompanyDetailsResult;

  if (companyData.impactFactors !== companyData.previousImpactFactors){
    data['impactFactors'] = companyData.impactFactors;
  }
  if (companyData.competitors !== companyData.previousCompetitors){
    data['competitors'] = companyData.competitors;
  }

  if (!data.impactFactors && !data.competitors){
    // No data to path
    return { status: false, error: 'Same as previous entry!'};
  }

  await axios.patch(`${api}/companies/${companyData.name}`, 
    data,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
      if (response.status) {
        result = { status: true };
        return;
      }

      result = { status: false, error: 'Error updating Company details!'};
    })
    .catch(function (error) {
      result = { status: false, error: 'Company details not updated!. Server unavailable.'};
    });

  return result;
}

export function getUsers(dispatch) {
  const rootDispatcher = new RootDispatcher(dispatch);
  axios
    .get(`${api}/users`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
      rootDispatcher.updateUserList(response.data);
    })
    .catch(function (error) {
      console.error('getUsersErr:', error);
    })
}

export interface GetCommentsData {
  type: string,
  companyId?: string,
  simulationId?: string,
  page?: number,
  pageSize?: number,
}

export interface CommentDetailsResult {
  status: boolean;
  error?: string;
  data?: any;
}

export async function getComments(data: GetCommentsData): Promise<CommentDetailsResult> {
  let result: CommentDetailsResult = { status: false };
  const params = {
    type: data.type,
    pageNumber: data.page || 0,
    pageSize: data.pageSize || 50
  };

  if (data.companyId) params['companyId'] = data.companyId;
  if (data.simulationId) params['simulationId'] = data.simulationId;

  await axios.get(`${api}/comments`,  {
    params,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
  }).then(function (response) {
      if (response.status) {
        result = { status: true, data: response.data };
        return;
      }

      result = { status: false, error: 'Error obtaing comments!'};
    })
    .catch(function (error) {
      result = { status: false, error: 'Comments not ready!. Server unavailable'};
    });

  return result;
}

export interface CommentData {
  content: string;
  type: string;
  companyId?: string;
  simulationId?: string;
  mentionedPeopleIds?: [];
  mentionedCompaniesIds?: [];
  mentionedSimulationsIds?: [];
}

export async function saveComment(commentData: CommentData): Promise<CommentDetailsResult> {
  let params: CommentData = {
    content: commentData.content,
    type: commentData.type,
  };
  let result: CommentDetailsResult;

  if (commentData.simulationId){
    params['simulationId'] = commentData.simulationId;
  }
  if (commentData.companyId){
    params['companyId'] = commentData.companyId;
  }

  if (commentData.mentionedPeopleIds && commentData.mentionedPeopleIds.length){
    params['mentionedPeopleIds'] = commentData.mentionedPeopleIds;
  }
  if (commentData.mentionedCompaniesIds && commentData.mentionedCompaniesIds.length){
    params['mentionedCompaniesIds'] = commentData.mentionedCompaniesIds;
  }
  if (commentData.mentionedSimulationsIds && commentData.mentionedSimulationsIds.length){
    params['mentionedSimulationsIds'] = commentData.mentionedSimulationsIds;
  }

  await axios.post(`${api}/comments`, params, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
      if (response.status) {
        result = { status: true };
        return;
      }

      result = { status: false, error: 'Comment not saved!'};
    })
    .catch(function (error) {
      const message = (error.response && error.response.data && error.response.data.message) ?
        error.response.data.message
        : 'COmment not saved!. Server unavailable';
      result = { status: false, error: message};
    });

  return result;
}

export interface CommentEditData {
  content: string;
  type: string;
  companyId?: string;
  simulationId?: string;
  mentionedPeopleIds?: [];
  mentionedCompaniesIds?: [];
  mentionedSimulationsIds?: [];
  displayOnDashboard: boolean;
}

export async function editComment(id: string, commentData: CommentEditData): Promise<CommentDetailsResult> {

  if (!id) return { status: false, error: 'no comment id!'};

  let params: CommentEditData = {
    content: commentData.content,
    type: commentData.type,
    displayOnDashboard: commentData.displayOnDashboard,
  };
  let result: CommentDetailsResult;

  if (commentData.simulationId){
    params['simulationId'] = commentData.simulationId;
  }
  if (commentData.companyId){
    params['companyId'] = commentData.companyId;
  }

  if (commentData.mentionedPeopleIds && commentData.mentionedPeopleIds.length){
    params['mentionedPeopleIds'] = commentData.mentionedPeopleIds;
  }
  if (commentData.mentionedCompaniesIds && commentData.mentionedCompaniesIds.length){
    params['mentionedCompaniesIds'] = commentData.mentionedCompaniesIds;
  }
  if (commentData.mentionedSimulationsIds && commentData.mentionedSimulationsIds.length){
    params['mentionedSimulationsIds'] = commentData.mentionedSimulationsIds;
  }

  await axios.put(`${api}/comments/${id}`, params, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
      if (response.status) {
        result = { status: true };
        return;
      }

      result = { status: false, error: 'Comment not updated!'};
    })
    .catch(function (error) {
      const message = (error.response && error.response.data && error.response.data.message) ?
        error.response.data.message
        : 'Comment not updated!. Server unavailable.';
      result = { status: false, error: message};
    });

  return result;
}

export async function deleteComment(id: string){
  if (!id) return { status: false, error: 'no comment id!'};

  let result: CommentDetailsResult;

  await axios.delete(`${api}/comments/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
  })
  .then(function (response) {
    if (response.status) {
      result = { status: true };
      return;
    }

    result = { status: false, error: 'Comment couldn\'t be deleted!'};
  })
  .catch(function (error) {
    result = { status: false, error: 'Comment not deleted!. Server unavailable.'};
  });

  return result;
}

export interface CompaniesDetailsResult {
  status: boolean;
  error?: string;
  data?: any;
}

export async function getCompaniesByName(searchTerm: string): Promise<CompaniesDetailsResult> {
  let result: CompaniesDetailsResult = { status: false };

  if (refreshToken(getCompaniesByName, arguments)) return;

  await axios.get(`${api}/companies`, {
    params: {
      searchPhrase: searchTerm,
      pageNumber: 0,
      pageSize: 1000
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
  })
  .then(function (response) {
    if (!response.status) return { status: false, error: 'Error occured while loading companies list!' };

    if (response.data && response.data.content && response.data.content.length > 0) {
      result = { status: true, data: response.data.content };
    } else {
      result = { status: false, error: 'Companies list is unavailable!' };
    }
  })
  .catch(function (error) {
    result = { status: false, error: 'Error occured while loading companies list!' };
  });
  return result;
}

export async function getFullCompaniesList(dispatch): Promise<null> {
  let result: CompaniesDetailsResult = { status: false, data: [] };
  const rootDispatcher = new RootDispatcher(dispatch);
  if (refreshToken(getCompaniesByName, arguments)) return;

  await axios.get(`${api}/companies`, {
    params: {
      searchPhrase: '',
      pageNumber: 0,
      pageSize: 5000
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
  })
  .then(function (response) {
    if (!response.status) return { status: false, error: 'Error occured while loading companies list!' };

    if (response.data && response.data.content && response.data.content.length > 0) {
      result = { status: true, data: response.data.content };
      rootDispatcher.SetCompaniesNameList(result.data);
    } else {
      result = { status: false, error: 'No companies matching the criteria!' };
      rootDispatcher.SetCompaniesNameList([]);
    }
  })
  .catch(function (error) {
    result = { status: false, error: 'Error occured while loading companies list!' };
    rootDispatcher.SetCompaniesNameList([]);
  });
}

export interface CompaniesIdByNameDetailsResult {
  status: boolean;
  error?: string;
  data?: any;
}

export async function getCompaniesIdByName(companyIds): Promise<CompaniesIdByNameDetailsResult> {
  let result: CompaniesIdByNameDetailsResult;
  let searchTerm = "";
  companyIds.forEach((company) => {
    searchTerm += `&names=${company}`;
  });

  if (refreshToken(getCompaniesIdByName, arguments)) return;

  await axios.get(`${api}/companies/byNames?${searchTerm}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
  })
  .then(function (response) {
    if (response.status) {
      result = { status: true, data: response.data };
    }else{
      result = { status: false, error: 'Companies data not ready!'};
    }
  })
  .catch(function (error) {
    result = { status: false, error: 'Companies data not ready!. Server unavailable.'};
  });

  return result;
}

export async function sendSimulationEmail(simulationId: string, recipientsId) {
  let returnValue = { status: false, message: ""};
  await axios
    .post(`${api}/simulations/${simulationId}/share`, {
      recipientIds: recipientsId
    },{
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      if (!response.status){
        returnValue.message = "Error sharing simulation!";
        return;
      }
      returnValue.status = true;
      returnValue.message = "Simulation shared!";

    }, (error)=>{
      console.error('send sim error', error);
      returnValue.message = "Error sharing simulation!";
    });

  return returnValue;
}

/* PROGRAMS */
export async function getProgramList(dispatch) {
  if (refreshToken(getProgramList, arguments)) return;

  const rootDispatcher = new RootDispatcher(dispatch);

  await axios
    .get(`${api}/programs/simple`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
      if (response.data.length > 0) {
        // order programs list by name
        const orderedProgramList = response.data.sort((a, b) => (a.name > b.name) ? 1 : -1);
        rootDispatcher.updateProgramList(orderedProgramList);
      } else {
        rootDispatcher.updateProgramList([]);
        console.error('User is not assigned to any programs!');
      }
    })
    .catch(function (error) {
      console.error('Error occured while loading program list!');
    });
}

export async function getCurrentProgram(dispatch) {
  if (refreshToken(getCurrentProgram, arguments)) return;

  const rootDispatcher = new RootDispatcher(dispatch);

  await axios
    .get(`${api}/programs/current`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then(function (response) {
      if (response.data && response.data.id) {
        rootDispatcher.updateCurrentProgram(response.data);
      } else {
        console.error('Current Program of user is unavailable!');
      }
    })
    .catch(function (error) {
      console.error('Error occured while loading current program of user!');
    });
}

export interface SelectProgramResult {
  status: boolean;
  message?: string;
  data?: any;
}

export async function selectProgram(dispatch, programId): Promise<SelectProgramResult> {
  let returnValue = { status: false, message: "", data: null };

  if (!programId) return;
  if (refreshToken(selectProgram, arguments)) return;

  const rootDispatcher = new RootDispatcher(dispatch);

  await axios.post(`${api}/programs/${programId}/setCurrent`, {
      id: programId,
    },{
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    }
  )
  .then((response) => {
    if (response.data && response.data.id) {
      rootDispatcher.updateCurrentProgram(response.data);
      returnValue.status = true;
      returnValue.data = response.data;
    } else {
      console.error('Couldn\'t update current program of user!');
      returnValue.message = "Couldn\'t update current program of user!";
    }

  })
  .catch(function (error) {
    console.error('Error occured while updating selected program of user!');
    returnValue.message = "Error occured while updating selected program of user!";
  });

  return returnValue;
}

export function getCalculationsRange(dispatch): void {
  if (refreshToken(getCalculationsRange, arguments)) return;

  const rootDispatcher = new RootDispatcher(dispatch);
  axios.get(`${api}/calculations/range`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
  })
    .then(function (response) {
      if (response.status && response.data) {
        rootDispatcher.updateCalculationsRange(response.data);
      } else {
        console.error('Calculations Range data is unavailable!');
      }
    })
    .catch(function (error) {
      console.error('Error occured while loading calculations range data!');
    });
}
