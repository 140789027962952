/**************************************
 ** 3rd Party components and libraries
 ***************************************/
import React from 'react';
import {Action, Dispatch, Reducer} from 'redux';
/**************************************
 ** Internal components and libraries
 ***************************************/
import {AggregatedByTierProps, SingleSimulatorProps} from '../../helpers/simulator-helpers'
import {ScatterCompany} from '../../helpers/data-canvas-helpers'
import DataCanvasSettings from 'app/components/nodes/data-canvas/DataCanvasSettings/DataCanvasSettings';
/**************************************
 ***********|| STATE ||***********
 ***************************************/
export interface InitialState {
  isAuthed: boolean;
  appTourStep:number;
  appTour:boolean;
  currentURI: string;
  isSidebarOpen: boolean;

  /******************************
  //=== DASHBOARD ===//
  ******************************/
  mobileDashView: string,
  sidebarProps: {
    header: string,
    body?: React.ReactNode,
    alts?: {
      header: string,
      body: React.ReactNode
    }[]
  };
  startDate: string;
  endDate: string;
  riskRows: {
    companyCity: string;
    companyCountry: string;
    companyFunction: string;
    companyName: string;
    totalMetric: number;
  }[];
  impactRows: {
    companyCity: string;
    companyCountry: string;
    companyFunction: string;
    companyName: string;
    totalMetric: number;
  }[];
  revAtRiskRows: {
    companyCity: string;
    companyCountry: string;
    companyFunction: string;
    companyName: string;
    totalMetric: number;
  }[];
  selectedTrendCompany: {};
  riskTrendsArray: number[],
  impactTrendsArray: number[],
  revenueTrendsArray: number[],
  dateTrendsArray: string[],

  /******************************
  //=== NETWORK ===//
  ******************************/
  selectedNetworkView: string;
  selectedAdminView: string;
  selectedTreeView: boolean;
  mapObjects: {
    region: string;
    count: number;
    function: string;
    avgRiskScore: number;
    avgSimulatedRiskScore: number;
    avgImpactScore: number;
    avgSimulatedImpactScore: number;
    companyTier?: number;
    regionCoordinate: {
      lat: number;
      lng: number;
    }
    subRegionsCoordinates: {
      lat: number;
      lng: number;
    }[];
    companiesAndTheirRiskScore: {
      name: string;
      riskScore: number;
    }[];
  }[];
  treeTiersList: AggregatedByTierProps[];
  clusterTiersList: AggregatedByTierProps[];
  companyTree: {
    parentCompanyName: string;
    companyTreeList: AggregatedByTierProps[];
  };
  nodeTree: {
    parentCompanyName: string;
    companyTreeList: AggregatedByTierProps[];
  };
  tempNodeTree: {
    parentCompanyName: string;
    companyTreeList: AggregatedByTierProps[];
  };
  cmpListObjects: {
    companyName: string;
    avgRiskScore: number;
    avgImpactScore: number;
    lat: number;
    lng: number;

  }[];
  aggNodeCompaniesList: {
    region: string,
    companyDetails: {
      name: string;
      riskScore: number;
    }[]
  };
  nodeDetailRiskTrends: {
    date: [];
    riskScore: [];
  };

  /******************************
  //=== NODES ===//
  ******************************/
  selectedNodePageView: boolean,
  selectedNodeView: string,
  nodeList: [];
  advancedAnalytics: {},
  nodeDetailInfo: {
    cmpId: string;
    cmpName: string;
    cmpTier: string;
    cmpFunction: string;
    cmpCountry: string;
    cmpCity: string;
    cmpImpactFactors: string;
    cmpCompetitors: string;
  };
  nodeDetailData: {
    impactScore: number;
    revenue: number;
    riskScore: number;
    riskTrend: number;
  }
  nodeRiskOverviewObj: {
    overallTimingRisk: number;
  };
  nodeTimingDeliveriesObj: {
    startDate: string;
    endDate: string;
    nodeTimingDeliveriesList: {
      dueDate: string;
      receiptDate: string;
      orderID: string;
    }[];
  }
  nodeTimingDeliveriesTemp: {
    startDate: string;
    endDate: string;
    nodeTimingDeliveriesList: {
      dueDate: string;
      receiptDate: string;
      orderID: string;
    }[];
  }
  /** Data Canvas **/
  scatterPlotCompanies: ScatterCompany[];
  selectedChartType: string;
  selectedChartView: string;
  currentChartOptions: string;
  chartTitle: string;
  hasZAxis: boolean;
  paramX: string;
  paramY: string;
  paramZ: string;
  selectedCanvasCompanies: {name:string, id:string}[]
  canvasAxes: {value:string, title:string}[],
  dataCanvasSelection: {
    chartTitle: string;
    selectedChartType: string;
    selectedChartView: string;
    canvasAxes: {value:string, title:string}[],
  },
  dataCanvasFilters: {
    companyFunction: string[],
    countryName: string[],
    continentName: string[],
    riskCat: string[],
  };
  companiesNameList: {
    id: string,
    name: string,
  }[];
  /******************************
  //=== SIMULATOR ===//
  ******************************/
  isSimulatorOpen: boolean;
  isSimulatorReduced: boolean;
  selectedSimView: string;
  accessType: string;
  progressState: string;
  sharedAccessType: string;
  createdSimulationAccessType: string;
  simCalcDone: boolean;
  /** Create New Sim **/
  selectableSimCompanies: {}[];
  newSimSelectedNodes: {}[];
  addedCompanyToSim: {
    name: string;
    riskScore: number;
  };
  /** Sim Library **/
  currentSimulator: SingleSimulatorProps;
  simulatorList: SingleSimulatorProps[];
  simulatorSharedList: SingleSimulatorProps[];
  simModeActive: boolean;
  simToReset : boolean;

  /******************************
  //=== HELP PAGE ===//
  ******************************/
  selectedHelpView: string;
  // COMMON DATA //
  companyList: {}[];
  userList: {}[];
  programList: {}[];
  currentProgram: {
    id: string,
    name: string,
  },
  calculationsRange: {
    firstCalculationDate: string,
    lastCalculationDate: string,
  },
}

export const initialState: InitialState = {
  isAuthed: false,
  appTourStep:-1,
  appTour:undefined,
  currentURI: '',
  isSidebarOpen: true,

  /******************************
  //=== DASHBOARD ===//
  ******************************/
  mobileDashView: 'Revenue at Risk',
  sidebarProps: {
    header: '',
    alts: [],
  },
  startDate: '',
  endDate: '',
  riskRows: null,
  impactRows: null,
  revAtRiskRows: null,
  selectedTrendCompany: {},
  riskTrendsArray: [],
  impactTrendsArray: [],
  revenueTrendsArray: [],
  dateTrendsArray: [],

  /******************************
  //=== NETWORK ===//
  ******************************/
  mapObjects: [],
  treeTiersList: [],
  clusterTiersList: [],
  selectedNetworkView: '',
  selectedAdminView: '',
  companyTree: {
    parentCompanyName: "",
    companyTreeList: []
  },
  nodeTree: {
    parentCompanyName: "",
    companyTreeList: []
  },
  tempNodeTree: {
    parentCompanyName: "",
    companyTreeList: []
  },
  selectedTreeView: true,

  /******************************
  //=== NODES ===//
  ******************************/
  nodeList: [],
  advancedAnalytics: {
    companyName: '',
    riskBar: [],
    impactBar: [],
    revArea: [],
    riskArea: [],
  },
  selectedNodePageView: true,
  selectedNodeView: 'nodeList',
  nodeDetailInfo: {
    cmpId: '',
    cmpName: '',
    cmpTier: '',
    cmpFunction: '',
    cmpCountry: '',
    cmpCity: '',
    cmpImpactFactors: '',
    cmpCompetitors: ''
  },
  nodeDetailData: {
    impactScore: 0,
    revenue: 0,
    riskScore: 0,
    riskTrend: 0,
  },
  nodeRiskOverviewObj: {
    overallTimingRisk: 0
  },
  nodeTimingDeliveriesObj: {
    startDate: "",
    endDate: "",
    nodeTimingDeliveriesList: []
  },
  nodeTimingDeliveriesTemp: {
    startDate: "",
    endDate: "",
    nodeTimingDeliveriesList: []
  },
  nodeDetailRiskTrends: {
    date: [],
    riskScore: []
  },
  /** Data Canvas **/
  scatterPlotCompanies: [],
  selectedChartType: '',
  selectedChartView: '',
  currentChartOptions: '',
  chartTitle: '',
  hasZAxis: false,
  paramX: '',
  paramY: '',
  paramZ: '',
  dataCanvasSelection: {
    selectedChartType: 'Scatter Plot',
    selectedChartView: '2d',
    chartTitle: '',
    canvasAxes:[],
  },
  dataCanvasFilters: {
    companyFunction: [],
    countryName: [],
    continentName: [],
    riskCat: [],
  },
  canvasAxes:[],
  companiesNameList:[],
  selectedCanvasCompanies:[],

  /******************************
  //=== SIMULATOR ===//
  ******************************/
  isSimulatorOpen: false,
  isSimulatorReduced: false,
  selectedSimView: '',
  accessType: 'Private',
  progressState: 'DONE',
  sharedAccessType: 'Private',
  createdSimulationAccessType: 'Public',
  simCalcDone: false,
  selectableSimCompanies: [],
  newSimSelectedNodes: [],
  addedCompanyToSim: {
    name: null,
    riskScore: 0
  },
  cmpListObjects: [],
  aggNodeCompaniesList: {
    region: "",
    companyDetails: []
  },
  currentSimulator: {
    creationDate: '',
    category: '',
    name: '',
    comments: [],
    id: '',
    isPrivate: false,
    status: '',
    creator: {
      id: '',
      name: '',
      email: '',
    },
    inputs: [],
    scenario: '',
  },
  simulatorList: [],
  simulatorSharedList: [],
  simModeActive: false,
  simToReset : false,

  /******************************
  //=== HELP PAGE ===//
  ******************************/
  selectedHelpView: '',
  companyList: [],
  userList: [],
  programList: null,
  currentProgram: null,
  calculationsRange: null,
};

/**************************************
 ***********|| ACTIONS ||***********
 ***************************************/
export interface DispatchAction extends Action {
  payload: Partial<InitialState>;
}

export enum ActionType {
  SetIsAuthed,
  setAppTourStep,
  SetAppTour,
  UpdateCurrentURI,
  UpdateIsSidebarOpen,
  UpdateSidebarProps,

  /******************************
  //=== DASHBOARD ===//
  ******************************/
  UpdateMobileDashView,
  UpdateStartDate,
  UpdateEndDate,
  UpdateRiskRows,
  UpdateImpactRows,
  UpdateRevAtRiskRows,
  UpdateSelectedTrendCompany,
  UpdateRiskTrendsArray,
  UpdateImpactTrendsArray,
  UpdateRevenueTrendsArray,
  UpdateDateTrendsArray,

  /******************************
  //=== NETWORK ===//
  ******************************/
  UpdateMapObjects,
  UpdateTreeTiersList,
  UpdateTempNodeTree,
  UpdateCompanyTreeList,
  UpdateSelectedTreeView,
  UpdateClusterTiersList,
  UpdateCmpListObjects,
  UpdateAggNodeCompaniesList,//list of companies added to sim
  UpdateNodeDetailRiskTrends,
  UpdateSelectedNetworkView,
  UpdateSelectedAdminView,
  UpdateSelectedSimCompanies,

  /******************************
  //=== NODES ===//
  ******************************/
  UpdateSelectedNodePageView,// true if node page selected
  UpdateSelectedNodeView, // nodeList, nodeDetails
  UpdateNodeDetailInfo, // update node details widget
  UpdateNodeDetailData,
  UpdateNodeTree,
  UpdateNodeRiskOverviewObj,
  UpdateNodeList,
  UpdateAdvancedAnalytics,
  UpdateNodeTimingDeliveriesList,
  UpdateNodeTimingDeliveriesTempList,
  RemoveNodeDetailInfo,
  UpdateSensitivityBestNodeList,
  UpdateSensitivityWorstNodeList,
  /** Data Canvas **/
  SetScatterPlotCompanies,
  SetSelectedChartType,
  SetSelectedChartView,
  SetCurrentChartOptions,
  SetChartTitle,
  SetHasZAxis,
  SetParamX,
  SetParamY,
  SetParamZ,
  // SetNameValsArr,
  // SetSizeValsArr,
  // SetColorValsArr,
  // SetXValsArr,
  // SetYValsArr,
  // SetZValsArr,
  SetCanvasSelection,
  SetCanvasFilters,
  SetCanvasAxes,
  SetCompaniesNameList,
  SetSelectedCanvasCompanies,

  /******************************
  //=== SIMULATOR ===//
  ******************************/
  UpdateSelectableSimCompanies,
  UpdateAddedCompaniesToSim,
  UpdateSimulationList,
  UpdateSimulationSharedList,
  SetIsSimulatorOpen,
  SetIsSimulatorReduced,
  SetSelectedSimView,
  SetAccessType,
  SetProgressState,
  SetSharedAccessType,
  SetCurrentSimulator,
  SetCreatedSimulationAccessType,
  SetSimCalcDone,
  UpdateNewSimSelectedNodes,
  CreateNewSimObj,
  SetSimModeActive,
  SetSimToReset,

  /******************************
  //=== HELP ===//
  ******************************/
  UpdateSelectedHelpView,
  // Common Data //
  UpdateCompanyList,
  UpdateUserList,
  UpdateProgramList,
  UpdateCurrentProgram,
  UpdateCalculationsRange,
}

/**************************************
 ***********|| REDUCERS ||***********
 ***************************************/
const A = ActionType;

export const rootReducer: Reducer<InitialState, DispatchAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case A.SetIsAuthed:
      return {...state, isAuthed: action.payload.isAuthed};
    case A.setAppTourStep:
        return {...state, appTourStep: action.payload.appTourStep};
    case A.SetAppTour:
        return {...state, appTour: action.payload.appTour};
    case A.UpdateCurrentURI:
      return {...state, currentURI: action.payload.currentURI};
    case A.UpdateIsSidebarOpen:
      return {...state, isSidebarOpen: action.payload.isSidebarOpen}
    case A.UpdateSidebarProps:
      const sidebarProps = action.payload.sidebarProps;
      sidebarProps.alts = sidebarProps.alts?sidebarProps.alts:[]
      return {
        ...state,
        sidebarProps: sidebarProps,
      }
    case A.UpdateStartDate:
      return {...state, startDate: action.payload.startDate};
    case A.UpdateEndDate:
      return {...state, endDate: action.payload.endDate};

    /******************************
    //=== DASHBOARD ===//
    ******************************/
    case  A.UpdateMobileDashView:
      return {...state, mobileDashView: action.payload.mobileDashView};
    case A.UpdateRiskRows:
      return {...state, riskRows: action.payload.riskRows};
    case A.UpdateImpactRows:
      return {...state, impactRows: action.payload.impactRows};
    case A.UpdateRevAtRiskRows:
      return {...state, revAtRiskRows: action.payload.revAtRiskRows};
    case A.UpdateSelectedTrendCompany:
      return {...state, selectedTrendCompany: action.payload.selectedTrendCompany};
    case A.UpdateRiskTrendsArray:
      return {...state, riskTrendsArray: action.payload.riskTrendsArray};
    case A.UpdateImpactTrendsArray:
      return {...state, impactTrendsArray: action.payload.impactTrendsArray};
    case A.UpdateRevenueTrendsArray:
      return {...state, revenueTrendsArray: action.payload.revenueTrendsArray};
    case A.UpdateDateTrendsArray:
      return {...state, dateTrendsArray: action.payload.dateTrendsArray};

    /******************************
    //=== NETWORK ===//
    ******************************/
    /** MAP **/
    case A.UpdateMapObjects:
      return {...state, mapObjects: action.payload.mapObjects};
    /** CASCADE TREE **/
    case A.UpdateTreeTiersList:
      return {...state, treeTiersList: action.payload.treeTiersList};
    case A.UpdateTempNodeTree:
      return {...state, tempNodeTree: action.payload.tempNodeTree};
    case A.UpdateCompanyTreeList:
      return {...state, companyTree: action.payload.companyTree};
    case A.UpdateSelectedTreeView:
      return {...state, selectedTreeView: action.payload.selectedTreeView};
    case A.UpdateNodeDetailRiskTrends:
      return {...state, nodeDetailRiskTrends: action.payload.nodeDetailRiskTrends};
    /** CLUSTER **/
    case A.UpdateClusterTiersList:
      return {...state, clusterTiersList: action.payload.clusterTiersList};
    case A.UpdateCmpListObjects:
      return {...state, cmpListObjects: action.payload.cmpListObjects};
    case A.UpdateAggNodeCompaniesList:
      return {...state, aggNodeCompaniesList: action.payload.aggNodeCompaniesList};
    case A.UpdateSelectedNetworkView:
      return {...state, selectedNetworkView: action.payload.selectedNetworkView};
    case A.UpdateSelectedAdminView:
      return {...state, selectedAdminView: action.payload.selectedAdminView};
    case A.UpdateSelectableSimCompanies:
      return {...state, selectableSimCompanies: action.payload.selectableSimCompanies};
    case A.UpdateAddedCompaniesToSim:
      return {...state, addedCompanyToSim: action.payload.addedCompanyToSim};

    /******************************
    //=== SIMULATOR ===//
    ******************************/
    case A.SetIsSimulatorOpen:
      return {...state, isSimulatorOpen: action.payload.isSimulatorOpen};
    case A.SetIsSimulatorReduced:
      return {...state, isSimulatorReduced: action.payload.isSimulatorReduced};
    case A.UpdateNewSimSelectedNodes:
      return {...state, newSimSelectedNodes: action.payload.newSimSelectedNodes};
    case A.SetSelectedSimView:
      return {...state, selectedSimView: action.payload.selectedSimView};
    case A.SetAccessType:
      return {...state, accessType: action.payload.accessType};
    case A.SetProgressState:
        return {...state, progressState: action.payload.progressState};
    case A.SetSharedAccessType:
      return {...state, sharedAccessType: action.payload.sharedAccessType};
    case A.SetCurrentSimulator:
      return {...state, currentSimulator: action.payload.currentSimulator}
    case A.SetCreatedSimulationAccessType:
      return {...state, createdSimulationAccessType: action.payload.createdSimulationAccessType};
    case A.UpdateSimulationList:
      return {...state, simulatorList: action.payload.simulatorList}
    case A.UpdateSimulationSharedList:
      return {...state, simulatorSharedList: action.payload.simulatorSharedList}
    case A.SetSimModeActive:
      return {...state, simModeActive: action.payload.simModeActive};
    case A.SetSimCalcDone:
      return {...state, simCalcDone: action.payload.simCalcDone};
    case A.SetSimToReset:
      return {...state, simToReset: action.payload.simToReset};
    case A.UpdateCompanyList:
      return {...state, companyList: action.payload.companyList};
    case A.UpdateUserList:
      return {...state, userList: action.payload.userList};
    case A.UpdateProgramList:
      return {...state, programList: action.payload.programList};
    case A.UpdateCurrentProgram:
      return {...state, currentProgram: action.payload.currentProgram};
    case A.UpdateCalculationsRange:
      return {...state, calculationsRange: action.payload.calculationsRange};

    /******************************
    //=== NODES ===//
    ******************************/
    case A.UpdateNodeList:
      return {...state, nodeList: action.payload.nodeList}
    case A.UpdateAdvancedAnalytics:
      return {...state, advancedAnalytics: action.payload.advancedAnalytics}
    case A.UpdateSelectedNodePageView:
      return {...state, selectedNodePageView: action.payload.selectedNodePageView}
    case A.UpdateSelectedNodeView:
      return {...state, selectedNodeView: action.payload.selectedNodeView}
    case A.UpdateNodeDetailInfo:
      return {...state, nodeDetailInfo: action.payload.nodeDetailInfo,
        selectedCanvasCompanies: [{
          name: action.payload.nodeDetailInfo.cmpName,
          id: action.payload.nodeDetailInfo.cmpId
        }]}
    case A.UpdateNodeDetailData:
      return {...state, nodeDetailData: action.payload.nodeDetailData }
    case A.UpdateNodeTree:
      return {...state, nodeTree: action.payload.nodeTree};
    case A.UpdateNodeRiskOverviewObj:
      return {...state, nodeRiskOverviewObj: action.payload.nodeRiskOverviewObj};
    case A.UpdateNodeTimingDeliveriesList:
      return {...state, nodeTimingDeliveriesObj: action.payload.nodeTimingDeliveriesObj};
    case A.UpdateNodeTimingDeliveriesTempList:
        return {...state, nodeTimingDeliveriesTemp: action.payload.nodeTimingDeliveriesTemp};
    case A.RemoveNodeDetailInfo:
        return {...state, nodeDetailInfo: {
          cmpId: "",
          cmpName: "",
          cmpTier: '',
          cmpFunction: '',
          cmpCountry: '',
          cmpCity: '',
          cmpImpactFactors: '',
          cmpCompetitors: ''}
        }
    /** Data Canvas **/
    case A.SetScatterPlotCompanies:
      return{...state, scatterPlotCompanies: action.payload.scatterPlotCompanies};
    case A.SetSelectedChartType:
      return {...state, selectedChartType: action.payload.selectedChartType};
    case A.SetSelectedChartView:
      return {...state, selectedChartView: action.payload.selectedChartView};
    case A.SetCurrentChartOptions:
      return {...state, currentChartOptions: action.payload.currentChartOptions};
    case A.SetChartTitle:
      return {...state, chartTitle: action.payload.chartTitle};
    case A.SetHasZAxis:
      return {...state, hasZAxis: action.payload.hasZAxis};
    case A.SetParamX:
      return {...state, paramX: action.payload.paramX};
    case A.SetParamY:
      return {...state, paramY: action.payload.paramY};
    case A.SetParamZ:
      return {...state, paramZ: action.payload.paramZ};
    case A.SetCanvasSelection:
      return {...state, dataCanvasSelection: {...state.dataCanvasSelection, ...action.payload.dataCanvasSelection}};
    case A.SetCanvasFilters:
      return {...state, dataCanvasFilters: action.payload.dataCanvasFilters};
    case A.SetCanvasAxes:
      return {...state, canvasAxes: action.payload.canvasAxes};
    case A.SetCompaniesNameList:
      return {...state, companiesNameList: action.payload.companiesNameList};
    case A.SetSelectedCanvasCompanies:
      return {...state, selectedCanvasCompanies: action.payload.selectedCanvasCompanies};

    /******************************
    //=== HELP PAGE ===//
    ******************************/
    case A.UpdateSelectedHelpView:
      return {...state, selectedHelpView: action.payload.selectedHelpView};
    default:
      return state;
  }
};

/**************************************
 ***********|| DISPATCHERS ||**********
 ***************************************/
export class RootDispatcher {
  private readonly dispatch: Dispatch<DispatchAction>;

  constructor(dispatch: Dispatch<DispatchAction>) {
    this.dispatch = dispatch;
  }

  setIsAuthed = (isAuthed: boolean) =>
    this.dispatch({type: A.SetIsAuthed, payload: {isAuthed}})

  setAppTourStep = (appTourStep : number)=>
    this.dispatch({type: A.setAppTourStep,payload:{appTourStep}})

  setAppTour = (appTour: boolean) =>
    this.dispatch({type: A.SetAppTour, payload: {appTour}})

  updateCurrentURI = (currentURI: string) =>
    this.dispatch({type: A.UpdateCurrentURI, payload: {currentURI}})

  updateIsSidebarOpen = (isSidebarOpen: boolean) => {
    this.dispatch({type: A.UpdateIsSidebarOpen, payload: {isSidebarOpen}})
  }

  updateSidebarProps = (sidebarProps: {header: string, body?: React.ReactNode, alts?:{header:string, body:React.ReactNode}[]}) => {
    this.dispatch({type: A.UpdateSidebarProps, payload: {sidebarProps}})
  }

  updateStartDate = (startDate: string) =>
    this.dispatch({type: A.UpdateStartDate, payload: {startDate}});

  updateEndDate = (endDate: string) =>
    this.dispatch({type: A.UpdateEndDate, payload: {endDate}});

  /******************************
  //=== DASHBOARD ===//
  ******************************/
  updateMobileDashView = (mobileDashView) => 
    this.dispatch({type: A.UpdateMobileDashView, payload: {mobileDashView}});

  updateRiskRows = (riskRows) =>
    this.dispatch({type: A.UpdateRiskRows, payload: {riskRows}});

  updateImpactRows = (impactRows) =>
    this.dispatch({type: A.UpdateImpactRows, payload: {impactRows}});

  updateRevAtRiskRows = (revAtRiskRows) =>
    this.dispatch({type: A.UpdateRevAtRiskRows, payload: {revAtRiskRows}});

  updateSelectedTrendCompany = (selectedTrendCompany: {}) =>
    this.dispatch({type: A.UpdateSelectedTrendCompany, payload: {selectedTrendCompany}});

  updateRiskTrendsArray = (riskTrendsArray: number[]) =>
    this.dispatch({type: A.UpdateRiskTrendsArray, payload: {riskTrendsArray}});

  updateImpactTrendsArray = (impactTrendsArray: number[]) =>
    this.dispatch({type: A.UpdateImpactTrendsArray, payload: {impactTrendsArray}});

  updateRevenueTrendsArray = (revenueTrendsArray: number[]) =>
    this.dispatch({type: A.UpdateRevenueTrendsArray, payload: {revenueTrendsArray}});

  updateDateTrendsArray = (dateTrendsArray: string[]) =>
    this.dispatch({type: A.UpdateDateTrendsArray, payload: {dateTrendsArray}});

  /******************************
  //=== NETWORK ===//
  ******************************/
  updateMapObjects = (mapObjects: []) =>
    this.dispatch({type: A.UpdateMapObjects, payload: {mapObjects}});

  updateTreeTiersList = (treeTiersList: AggregatedByTierProps[]) =>
    this.dispatch({type: A.UpdateTreeTiersList, payload: {treeTiersList}});

  updateTempNodeTree = (tempNodeTree) =>
    this.dispatch({type: A.UpdateTempNodeTree, payload: {tempNodeTree}});

  updateCompanyTreeList = (companyTree) =>
    this.dispatch({type: A.UpdateCompanyTreeList, payload: {companyTree}});

  updateSelectedTreeView = (selectedTreeView: boolean) =>
    this.dispatch({type: A.UpdateSelectedTreeView, payload: {selectedTreeView}});

  updateClusterTiersList = (clusterTiersList: AggregatedByTierProps[]) =>
    this.dispatch({type: A.UpdateClusterTiersList, payload: {clusterTiersList}});

  updateCmpListObjects = (cmpListObjects: []) =>
    this.dispatch({type: A.UpdateCmpListObjects, payload: {cmpListObjects}});

  updateAggNodeCompaniesList = (aggNodeCompaniesList) =>
    this.dispatch({type: A.UpdateAggNodeCompaniesList, payload: {aggNodeCompaniesList}});

  updateNodeDetailRiskTrends = (nodeDetailRiskTrends) =>
    this.dispatch({type: A.UpdateNodeDetailRiskTrends, payload: {nodeDetailRiskTrends}});

  updateSelectedNetworkView = (selectedNetworkView: string) =>
    this.dispatch({type: A.UpdateSelectedNetworkView, payload: {selectedNetworkView}});

  updateSelectedAdminView = (selectedAdminView: string) =>
    this.dispatch({type: A.UpdateSelectedAdminView, payload: {selectedAdminView}});

  updateSelectableSimCompanies = (selectableSimCompanies) =>
    this.dispatch({type: A.UpdateSelectableSimCompanies, payload: {selectableSimCompanies}});

  updateAddedCompaniesToSim = (addedCompanyToSim) =>
    this.dispatch({type: A.UpdateAddedCompaniesToSim, payload: {addedCompanyToSim}});

  /******************************
  //=== SIMULATOR ===//
  ******************************/
  setIsSimulatorOpen = (isSimulatorOpen: boolean) =>
    this.dispatch({type: A.SetIsSimulatorOpen, payload: {isSimulatorOpen}});

  setIsSimulatorReduced = (isSimulatorReduced: boolean) =>
    this.dispatch({type: A.SetIsSimulatorReduced, payload: {isSimulatorReduced}});

  setSelectedSimView = (selectedSimView: string) =>
    this.dispatch({type: A.SetSelectedSimView, payload: {selectedSimView}});

  setAccessType = (accessType: string) =>
    this.dispatch({type: A.SetAccessType, payload: {accessType}});

  setProgressState = (progressState: string) =>
    this.dispatch({type: A.SetProgressState, payload: {progressState}});

  setSharedAccessType = (sharedAccessType: string) =>
    this.dispatch({type: A.SetSharedAccessType, payload: {sharedAccessType}});

  setCreatedSimulationAccessType = (createdSimulationAccessType: string) =>
    this.dispatch({type: A.SetCreatedSimulationAccessType, payload: {createdSimulationAccessType}});

  setSimCalcDone = (simCalcDone: boolean) =>
    this.dispatch({type: A.SetSimCalcDone, payload: {simCalcDone}});

  updateNewSimSelectedNodes = (newSimSelectedNodes: {}[]) =>
    this.dispatch({type: A.UpdateNewSimSelectedNodes, payload: {newSimSelectedNodes}})

  setCurrentSimulator = (currentSimulator: SingleSimulatorProps) =>
    this.dispatch({type: A.SetCurrentSimulator, payload: {currentSimulator}});

  updateSimulationList = (simulatorList: []) =>
    this.dispatch({type: A.UpdateSimulationList, payload: {simulatorList}});

  updateSimulationSharedList = (simulatorSharedList: []) =>
    this.dispatch({type: A.UpdateSimulationSharedList, payload: {simulatorSharedList}});

  setSimModeActive = (simModeActive: boolean) =>
    this.dispatch({type: A.SetSimModeActive, payload: {simModeActive}});

  setSimToReset = (simToReset: boolean) =>
    this.dispatch({type: A.SetSimToReset, payload: {simToReset}});

  /******************************
  //=== NODES ===//
  ******************************/
  updateNodeList = (nodeList: []) =>
    this.dispatch({type: A.UpdateNodeList, payload: {nodeList}});

  updateAdvancedAnalytics = (advancedAnalytics: {}) =>
    this.dispatch({type: A.UpdateAdvancedAnalytics, payload: {advancedAnalytics}})
  
  updateNodeTree = (nodeTree) =>
    this.dispatch({type: A.UpdateNodeTree, payload: {nodeTree}});

  updateSelectedNodePageView = (selectedNodePageView: boolean) =>
    this.dispatch({type: A.UpdateSelectedNodePageView, payload: {selectedNodePageView}});

  updateSelectedNodeView = (selectedNodeView: string) =>
    this.dispatch({type: A.UpdateSelectedNodeView, payload: {selectedNodeView}});

  updateNodeDetailInfo = (nodeDetailInfo) =>
    this.dispatch({type: A.UpdateNodeDetailInfo, payload: {nodeDetailInfo}});

  updateNodeDetailData = (nodeDetailData) =>
    this.dispatch({type: A.UpdateNodeDetailData, payload: {nodeDetailData}});

  updateNodeRiskOverviewObj = (nodeRiskOverviewObj) =>
    this.dispatch({type: A.UpdateNodeRiskOverviewObj, payload: {nodeRiskOverviewObj}});

  updateNodeTimingDeliveriesList = (nodeTimingDeliveriesObj) =>
    this.dispatch({type: A.UpdateNodeTimingDeliveriesList, payload: {nodeTimingDeliveriesObj}});

  updateNodeTimingDeliveriesTempList = (nodeTimingDeliveriesTemp) =>
    this.dispatch({type: A.UpdateNodeTimingDeliveriesTempList, payload: {nodeTimingDeliveriesTemp}});

  removeNodeDetailInfo = () =>
    this.dispatch({type: A.RemoveNodeDetailInfo, payload: {}})

  /** Data Canvas **/
  setScatterPlotCompanies = (scatterPlotCompanies) =>
    this.dispatch({type: A.SetScatterPlotCompanies, payload: {scatterPlotCompanies}});

  setSelectedChartType = (currentChartOptions) =>
    this.dispatch({type: A.SetCurrentChartOptions, payload: {currentChartOptions}});

  setSelectedChartView = (selectedChartView) =>
    this.dispatch({type: A.SetSelectedChartView, payload: {selectedChartView}});

  setChartTitle = (chartTitle) =>
    this.dispatch({type: A.SetChartTitle, payload: {chartTitle}});

  setHasZAxis = (hasZAxis) =>
    this.dispatch({type: A.SetHasZAxis, payload: {hasZAxis}});

  setParamX = (paramX) =>
    this.dispatch({type: A.SetParamX, payload: {paramX}});

  setParamY = (paramY) =>
    this.dispatch({type: A.SetParamY, payload: {paramY}});

  setParamZ = (paramZ) =>
    this.dispatch({type: A.SetParamZ, payload: {paramZ}});

  setCanvasSelection = (dataCanvasSelection) => {
    this.dispatch({type: A.SetCanvasSelection, payload: {dataCanvasSelection}});
  }

  setCanvasFilters = (dataCanvasFilters) => {
    this.dispatch({type: A.SetCanvasFilters, payload: {dataCanvasFilters}});
  }

  setCanvasAxes = (canvasAxes) => {
    this.dispatch({type: A.SetCanvasAxes, payload: {canvasAxes}});
  }

  SetCompaniesNameList = (companiesNameList) => {
    this.dispatch({type: A.SetCompaniesNameList, payload: {companiesNameList}})
  }

  SetSelectedCanvasCompanies = (selectedCanvasCompanies) => {
    this.dispatch({type: A.SetSelectedCanvasCompanies, payload: {selectedCanvasCompanies}})
  }
  /******************************
  //=== HELP PAGE ===//
  ******************************/
  updateSelectedHelpView = (selectedHelpView) =>
    this.dispatch({type: A.UpdateSelectedHelpView, payload: {selectedHelpView}});

  // COMMON //
  updateCompanyList = (companyList: []) =>
  this.dispatch({type: A.UpdateCompanyList, payload: {companyList}});

  updateUserList = (userList: []) =>
    this.dispatch({type: A.UpdateUserList, payload: {userList}});

  updateProgramList = (programList: {}[]) =>
    this.dispatch({type: A.UpdateProgramList, payload: {programList}});
  
  updateCurrentProgram = (currentProgram: { id: string, name: string }) =>
    this.dispatch({type: A.UpdateCurrentProgram, payload: {currentProgram}});

    updateCalculationsRange = (calculationsRange: { firstCalculationDate: string, lastCalculationDate: string }) =>
    this.dispatch({type: A.UpdateCalculationsRange, payload: {calculationsRange}});
}
