/**************************************
 ** 3rd Party components and libraries
***************************************/
import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonProgressBar } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useSelector, useDispatch } from 'react-redux';
import { InitialState, RootDispatcher } from './redux/store/root-reducer';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { Toaster } from 'react-hot-toast';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router'



export const DashboardPage = React.lazy(() => import('./pages/dashboard/DashboardPage'));
export const LogPage = React.lazy(() => import('./pages/log/LogPage'));
export const NetworkPage = React.lazy(() => import('./pages/network/NetworkPage'));
export const AdminPage = React.lazy(() => import('./pages/admin/AdminPage'));
export const NodesPage = React.lazy(() => import('./pages/nodes/NodesPage'));
export const DataCanvasPage = React.lazy(() => import('./pages/data-canvas/DataCanvasPage'));
export const Registration = React.lazy(() => import('./pages/registration/Registration'));
export const UserSettingsPage = React.lazy(() => import('./pages/user-settings/UserSettingsPage'));
export const HelpPage = React.lazy(() => import('./pages/help-page/HelpPage'));
export const ProfilePage = React.lazy(() => import('./pages/profile/ProfilePage'));

export const DesktopAppHeader = React.lazy(() => import('./containers/general/app-header/desktop-app-header/desktop-app-header'));
export const MobileAppHeader = React.lazy(() => import('./containers/general/app-header/mobile-app-header/mobile-app-header'));
export const MobileAppNav = React.lazy(() => import('./containers/general/app-nav/app-nav-mobile'));
export const SimModeIndicator = React.lazy(() => import('./components/general/sim-mode-indicator/SimModeIndicator'));
export const Simulator = React.lazy(() => import('./pages/simulator/Simulator'));

/**************************************
 ** Internal cpnts, libs, helpers
***************************************/
import { getFullCompaniesList, getProgramList, getCurrentProgram, selectProgram, getCalculationsRange } from './../../api/NetworkApi';
import {
  useIsMounted,
  saveUserDataToSessionStorage,
  validateAnyUserRoles,
  clearUserDataToSessionStorage,
  showToastMessage,
  hideToastMessage,
  isMobile,
  sendPageViewToGA,
  sendEventToGA,
} from './helpers/global-helpers';

/**************************************
 ** Component styles
***************************************/
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.scss';
import './app.scss';

interface ProgramInterface {
  id: string;
  name: string;
}

interface UserDataProps {
  isAuthed: boolean;
  programList: {}[];
  currentProgram: ProgramInterface;
}

interface InterfaceAppHeader {
  isMobile: boolean;
  programs: {}[];
  currentProgram: ProgramInterface;
  updateProgram: any;
}

// Set up Amplify data
const userPoolId = process.env.NX_USER_POOL_ID;
const userPoolWebClientId = process.env.NX_USER_POOL_WEB_CLIENT_ID;
const domain = process.env.NX_COGNITO_DOMAIN;

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: userPoolId,
    userPoolWebClientId: userPoolWebClientId,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      domain: domain,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'http://localhost:3000/api/login',
      redirectSignOut: 'http://localhost:3000/api/logout',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
});

// Set up Program Id for the user
let toastProgramId = null;
let currentLocation = '';

export const AppHeader = (props: InterfaceAppHeader): JSX.Element => {

  if (props.isMobile) {
    return (
      <>
        <MobileAppNav
          programs={props.programs}
          currentProgram={props.currentProgram}
          updateProgram={props.updateProgram}
        />
        <MobileAppHeader />
        <SimModeIndicator />
        <Simulator isSimulatorOpen={false} />
      </>
    );
  }

  if (!props.isMobile) {
    return (
      <>
        <DesktopAppHeader
          programs={props.programs}
          currentProgram={props.currentProgram}
          updateProgram={props.updateProgram}
        />
        <SimModeIndicator />
        <Simulator isSimulatorOpen={false} />
      </>
    );
  }
};

export const App: React.FC = () => {
  const isMounted = useIsMounted();
  const isMobileView = isMobile();
  const dispatch = useDispatch();
  const history = useHistory();
  const rootDispatcher = new RootDispatcher(dispatch);
  const [userHasAdminAccess, setUserHasAdminAccess] = useState(false);
  const [userHasStandardAccess, setUserHasStandardAccess] = useState(false);

  const [isRequestingAuth, setIsRequestingAuth] = useState(true);
  const {
    isAuthed,
    programList,
    currentProgram,
  } = useSelector<InitialState, UserDataProps>((state: InitialState) => {
    return {
      isAuthed: state.isAuthed,
      programList: state.programList,
      currentProgram: state.currentProgram,
    }
  });

  const checkIsLoggedIn = async () => {
    await Auth.currentAuthenticatedUser()
      .then(user => {
        saveUserDataToSessionStorage(user);
        rootDispatcher.setIsAuthed(true);

        // Check user roles
        setUserHasStandardAccess(validateAnyUserRoles(['standard']));
        setUserHasAdminAccess(validateAnyUserRoles(['admin', 'dataManager']));
        getFullCompaniesList(dispatch);
        setIsRequestingAuth(false);
        getProgramList(dispatch);
        getCurrentProgram(dispatch);
        getCalculationsRange(dispatch);
      })
      .catch(err => {
        console.log(err);
        setUserHasAdminAccess(false);
        setUserHasStandardAccess(false);
        rootDispatcher.setIsAuthed(false);
        clearUserDataToSessionStorage();
        setIsRequestingAuth(false);
      });
  }

  async function handleOnIdle() {
    try {
      await Auth.signOut();
      console.log('sign out success');
      rootDispatcher.setIsAuthed(false);
      clearUserDataToSessionStorage();
      clearCacheData();
      // Fill others here
      window.location.reload();
      history.push('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    // Cleared cache completely
  };

  async function updateProgram(programId) {
    if (currentProgram && currentProgram.id === programId) return;

    // Look up for program name
    let programName: string = "";
    programList.forEach((program: ProgramInterface) => {
      if (program.id === programId) {
        programName = program.name;
      }
    });

    if (!toastProgramId) {
      toastProgramId = showToastMessage(`Changing active program to "${programName}"...`, 'loading');
    } else {
      showToastMessage(`Changing active program to "${programName}"...`, 'loading', { id: toastProgramId });
    }

    const response = await selectProgram(dispatch, programId);

    if (!isMounted.current) return;
    if (!response || !response.status) {
      showToastMessage(response.message, 'error', { id: toastProgramId });
      return;
    }

    showToastMessage(`Active program changed to "${programName}"!`, 'success', { id: toastProgramId });

    // Clear Map, Hierarchical Nodes and Cluster view data
    rootDispatcher.updateMapObjects([]);
    rootDispatcher.updateTempNodeTree({});
    rootDispatcher.updateTreeTiersList([]);
    rootDispatcher.updateClusterTiersList([]);
    rootDispatcher.updateSelectedTreeView(true);
    getCalculationsRange(dispatch);
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60, //(ms to seconds, seconds to minutes, number of minutes)
    onIdle: async () => handleOnIdle(),
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500
  })

  useEffect(() => {
    if (!isMounted.current) return;

    setIsRequestingAuth(true);
    checkIsLoggedIn();

    return (() => {
      hideToastMessage();
    });
  }, []);

  useEffect(() => {
    if (!isMounted.current) return;
    if (currentLocation === location.pathname) return;

    // Send Page view to GA
    currentLocation = location.pathname;
    sendPageViewToGA(location.pathname, location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (!isMounted.current) return;
    if (isRequestingAuth) return;

    setIsRequestingAuth(true);
    checkIsLoggedIn();
  }, [isAuthed]);

  const appClass = isMobileView ? 'mobile-view' : '';
  const defaultAccessPage = (userHasStandardAccess) ? '/dashboard' : '/admin';

  return (
    <IonApp className={appClass} >
      <Suspense fallback={
        <div className="glass">
          <IonProgressBar type="indeterminate"></IonProgressBar>
        </div>
      }>
        {isRequestingAuth &&
          <div className="glass">
            <IonProgressBar type="indeterminate"></IonProgressBar>
          </div>
        }

        <IonReactRouter>
          <Toaster />
          {!isRequestingAuth && isAuthed && (
            <AppHeader
              isMobile={isMobileView}
              programs={userHasStandardAccess ? programList : []}
              currentProgram={currentProgram}
              updateProgram={updateProgram}
            />
          )}

          {!isRequestingAuth && isAuthed && (
            <IonRouterOutlet>
              <Switch>
                {userHasStandardAccess && <Route path="/dashboard" exact component={DashboardPage} />}
                {userHasStandardAccess && <Route path="/network" exact component={NetworkPage} />}
                {userHasStandardAccess && <Route path="/companies" exact component={NodesPage} />}
                {userHasStandardAccess && <Route path="/companies/:id" exact component={NodesPage} />}
                {userHasStandardAccess && <Route path="/data-canvas" exact component={DataCanvasPage} />}
                {userHasStandardAccess && <Route path="/log" exact component={LogPage} />}
                <Route path="/help" component={HelpPage} />
                <Route path="/profile" component={ProfilePage} />
                {/*userHasStandardAccess && <Route path="/settings" exact component={UserSettingsPage} />*/}
                {userHasStandardAccess && <Route path="/simulation/:id" exact component={NetworkPage} />}
                {userHasAdminAccess && <Route path="/admin" exact component={AdminPage} />}
                {userHasAdminAccess && <Route path="/admin/:id" exact component={AdminPage} />}

                <Redirect exact from="/" to={defaultAccessPage} />
                <Redirect
                  from="*"
                  to={{
                    pathname: defaultAccessPage,
                    state: { isAuthed: true, referrer: location.pathname },
                  }}
                />
              </Switch>
            </IonRouterOutlet>
          )}

          {!isRequestingAuth && !isAuthed && (
            <IonRouterOutlet>
              <Switch>
                <Route path="/login" exact component={Registration} />
                <Route path="/login/:id" exact component={Registration} />
                <Route path="/signUpUser" exact component={Registration} />
                <Route path="/forgotPassword" exact component={Registration} />
                <Route path="/forgotPassword/:id" exact component={Registration} />
                <Redirect
                  from="*"
                  to={{
                    pathname: '/login',
                    state: { isAuthed: false, referrer: location.pathname },
                  }}
                />
              </Switch>
            </IonRouterOutlet>
          )}

        </IonReactRouter>
      </Suspense>
    </IonApp>
  );
};

export default App;
